import {
  Text,
  VStack,
  Button,
  Card,
  CardBody,
  Container,
  Input,
  HStack,
  Box,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { BN } from "fuels";
import { useState } from "react";
import { useRedemptions } from "../../hooks/useRedemptions";
import { parseBN, PRECISION } from "../../shared/format";
import { useFluid } from "../../hooks/FluidProvider";

export const RedemptionCard = () => {
  const { redeemCollateral, isLoading } = useRedemptions();
  const { usdfBalance } = useFluid();
  const [amount, setAmount] = useState<BN>(new BN(0));
  const [amountText, setAmountText] = useState<string>("");

  const handleRedeem = async () => {
    await redeemCollateral(
      amount,
      10, // default max iterations
      5 // default partial redemption hint
    );
  };

  const isValidRedemption = () => {
    return amount.gt(new BN(0)) && amount.lte(usdfBalance);
  };

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    setAmountText(inputValue);

    // Remove commas and validate the number
    const sanitizedValue = inputValue.replace(/,/g, "");
    const isValidNumber = /^-?\d*(\.\d+)?$/.test(sanitizedValue);
    if (isValidNumber) {
      const parsedValue = parseFloat(sanitizedValue);
      setAmount(new BN(parsedValue * PRECISION));
    } else {
      setAmount(new BN(0));
    }
  };

  return (
    <Card
      variant={"darkCard"}
      fontFamily={"IBM Plex Mono"}
      fontWeight={"bold"}
      maxW="600px"
    >
      <CardBody w="100%" alignItems={"center"}>
        <VStack w={"100%"} gap={4}>
          <Text
            fontWeight={"semibold"}
            alignSelf={"start"}
            fontFamily={"IBM Plex Mono"}
            fontStyle={"bold"}
            display={"flex"}
            flexDir={"row"}
          >
            Redeem USDF
          </Text>

          <Container
            backgroundColor={"bgLightGrey"}
            py={4}
            borderRadius={10}
            pb={4}
            width={"100%"}
            maxW="none"
          >
            <VStack w={"100%"} gap={"2"}>
              <Text fontSize={"md"} textAlign={"left"}>
                Redeem your USDF for collateral from the riskiest troves.
              </Text>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>USDF in Wallet</Text>
                <Text pr={2}>{parseBN(usdfBalance, 9) + " USDF"}</Text>
              </HStack>

              <HStack
                fontSize={"md"}
                w={"100%"}
                justifyContent={"space-between"}
              >
                <Text color={"textSecondary"}>Amount</Text>
                <Box
                  w="100%"
                  borderRadius={10}
                  borderWidth="1px"
                  borderColor={
                    !isValidRedemption() && amountText !== ""
                      ? "red.500"
                      : "transparent"
                  }
                >
                  <HStack
                    w={"100%"}
                    justifyContent="space-between"
                    backgroundColor={"bgDarkGrey"}
                    borderRadius={10}
                  >
                    <Container
                      alignSelf={"flex-start"}
                      paddingLeft={"10px !important"}
                      flexDir={"row"}
                      display={"flex"}
                      placeSelf={"center"}
                    >
                      <Image
                        borderRadius="full"
                        alignSelf={"center"}
                        src={"https://i.imgur.com/qI2cyjF.jpg"}
                        alt="USDF Logo"
                        height={[6, 6, 7]}
                      />
                      <Text
                        fontSize="xl"
                        fontWeight="bold"
                        fontFamily="IBM Plex Mono"
                      >
                        {" "}
                        &nbsp;USDF
                      </Text>
                    </Container>
                    <VStack maxW={"50%"} gap={0}>
                      <Input
                        backgroundColor={"bgDarkGrey"}
                        textAlign={"right"}
                        placeholder="200 USDF"
                        fontSize={"xl"}
                        style={{ border: "none" }}
                        focusBorderColor="transparent"
                        borderRadius={"full"}
                        maxW={"100%"}
                        value={amountText}
                        onChange={handleAmountInputChange}
                        fontFamily="IBM Plex Mono"
                        fontWeight="bold"
                      />
                    </VStack>
                    <Button
                      h="1.75rem"
                      size="xs"
                      borderRadius={4}
                      px={4}
                      mr={2}
                      fontFamily="IBM Plex Mono"
                      fontWeight="bold"
                      isDisabled={!usdfBalance || usdfBalance.isZero()}
                      onClick={() => {
                        setAmount(usdfBalance);
                        setAmountText(parseBN(usdfBalance, 9));
                      }}
                    >
                      MAX
                    </Button>
                  </HStack>
                </Box>
              </HStack>

              <Tooltip
                label={
                  !isValidRedemption() && amount.gt(usdfBalance)
                    ? "Amount exceeds balance"
                    : ""
                }
                isDisabled={isValidRedemption()}
                hasArrow
              >
                <Button
                  // onClick={handleRedeem}
                  colorScheme={"green"}
                  w={"100%"}
                  isDisabled={!isValidRedemption()}
                  isLoading={isLoading}
                  fontFamily="IBM Plex Mono"
                  fontWeight="extrabold"
                >
                  Redeem
                </Button>
              </Tooltip>
            </VStack>
          </Container>
        </VStack>
      </CardBody>
    </Card>
  );
};
