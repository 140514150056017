import {
  Text,
  Link,
  Image,
  Button,
  HStack,
  ButtonGroup,
  Hide,
  Divider,
  Grid,
  GridItem,
  Show,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  IconButton,
  MenuDivider,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { BN } from "fuels";
import { useFluid } from "../../hooks/FluidProvider";
import { useFuel } from "@fuels/react";
import { useWallet } from "../../hooks/useWallet";
import {
  ChevronDownIcon,
  CopyIcon,
  ExternalLinkIcon,
  UnlockIcon,
  RepeatIcon,
  DownloadIcon,
  WarningIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import { formatBNtoHumanReadable, PRECISION } from "../../shared/format";
import { TestnetWarning } from "./TestnetWarning";
import { AssetMenuItem } from "./AssetMenuItem";
import { WalletAssets } from "./wallet-assets";
import { MintTestAssets } from "./MintTestAssets";
import FuelLogo from "../../images/fuel-logo.svg";
import { BorrowOperationsContract } from "../../types/borrow-operations-contract";
import { CollSurplusPoolContract } from "../../types/coll-surplus-pool-contract";
import {
  FaGithub,
  FaDiscord,
  FaBook,
  FaExchangeAlt,
  FaHistory,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoCubeOutline } from "react-icons/io5";
import { GoGraph } from "react-icons/go";
import { VscGraph } from "react-icons/vsc";
import { BsDatabase } from "react-icons/bs";
import { IAssetContext } from "../../types/fluid.types";
import { Address } from "fuels";

export const TitleBar = () => {
  const { account, isConnected, disconnect, connect } = useWallet();
  const toast = useToast();
  const {
    fptBalance,
    usdfBalance,
    assets,
    totalUsdfMinted,
    fptPrice,
    reloadData,
    isTestnet,
    contracts,
    setIsTestnet,
    stabilityPoolAPR,
    wallet,
  } = useFluid();
  const { fuel } = useFuel();
  const [assetsAdded, setAssetsAdded] = useState(false);

  async function onClickConnect() {
    if (isConnected) {
      disconnect();
      return;
    } else {
      connect();
    }
  }

  async function switchNetwork() {
    try {
      const currentNetwork = await fuel.currentNetwork();
      const currentConnector = await fuel.currentConnector();

      if (currentConnector?.name === "Ethereum Wallets") {
        if (isTestnet === "mainnet") {
          await fuel.selectNetwork({ chainId: 0 });
          setIsTestnet("testnet");
        } else {
          await fuel.selectNetwork({ chainId: 9889 });
          setIsTestnet("mainnet");
        }
      } else {
        if (currentNetwork.chainId === 0) {
          await fuel.selectNetwork({ chainId: 9889 });
        } else {
          await fuel.selectNetwork({ chainId: 0 });
        }
      }

      setTimeout(() => {
        reloadData();
      }, 1500);
    } catch (error) {
      console.error("Error switching network:", error);
    }
  }

  function copyAddress() {
    if (account) {
      navigator.clipboard.writeText(account);
      toast({
        description: "Address copied to clipboard!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  }

  function openExplorer() {
    if (account) {
      window.open(`https://app.fuel.network/account/${account}`, "_blank");
    }
  }
  const currentPage = window.location.pathname;
  const calculateTVL = () => {
    return assets.reduce((total, asset) => {
      const assetValue = asset.totalInActivePool
        .mul(asset.price)
        .div(PRECISION);
      return total.add(assetValue);
    }, new BN(0));
  };

  const tvl = calculateTVL();

  async function checkAndAddAssets() {
    const assets = await fuel.assets();
    let containsAll = true;

    if (assets) {
      const requiredAssets = [
        contracts.UsdfAssetId,
        contracts.assets[0].assetId,
        contracts.assets[1].assetId,
        contracts.fptAssetId,
      ];

      const existingAssets = assets.map((a) => a.name);
      containsAll = requiredAssets.every((asset) =>
        existingAssets.includes(asset)
      );
    }

    if (!containsAll) {
      await fuel.addAssets(WalletAssets);
    }
    setAssetsAdded(true);
    return !containsAll;
  }

  const hasCollateralSurplus = (assets: IAssetContext[]): boolean => {
    return assets.some((asset) => !asset.collateralSurplus.isZero());
  };

  async function claimCollateralSurplus(asset: IAssetContext) {
    if (!account || !contracts || !wallet) return;

    try {
      const borrowOperationsContract = new BorrowOperationsContract(
        contracts.BorrowOperations,
        wallet
      );

      const collateralSurplusPool = new CollSurplusPoolContract(
        contracts.collateralSurplusPool,
        wallet
      );

      const assetId = {
        bits: asset.contractIds.assetId,
      };

      await borrowOperationsContract.functions
        .claim_collateral(assetId)
        .addContracts([collateralSurplusPool])
        .txParams({ gasLimit: 5000000, variableOutputs: 4 })
        .call();

      toast({
        title: "Success",
        description: `Successfully claimed ${asset.symbol} collateral surplus`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });

      // Immediately update the specific asset's collateral surplus
      await reloadData(
        assets.findIndex(
          (a) => a.contractIds.assetId === asset.contractIds.assetId
        )
      );

      // Clear the cache for this asset's collateral surplus
      const myB256Address = Address.fromString(account).toB256();
      localStorage.removeItem(
        `collateralSurplus_${asset.contractIds.assetId}_${myB256Address}_${isTestnet}`
      );
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  }

  return (
    <>
      {isTestnet === "testnet" && <TestnetWarning />}
      <Grid
        templateColumns={["1fr auto auto", "1fr auto"]}
        alignItems="center"
        gap={2}
        width="100%"
        mb={2}
      >
        <GridItem colSpan={1}>
          <Hide below="md">
            <Text
              fontSize={"sm"}
              fontWeight={"semibold"}
              fontFamily={"IBM Plex Mono"}
              textAlign={"left"}
            >
              TVL&nbsp;
              {tvl.isZero() ? "-----" : formatBNtoHumanReadable(tvl, 9, 2)}
              &nbsp;USD &#183;{" "}
              {totalUsdfMinted.isZero()
                ? "-----"
                : formatBNtoHumanReadable(totalUsdfMinted, 9, 2)}
              &nbsp;USDF&nbsp;Minted &#183;{" "}
              <Tooltip
                label="Earn by depositing to the Stability Pool"
                hasArrow
              >
                <Text
                  as="span"
                  onClick={() => {
                    // link to /earn
                    window.open("/earn", "_self");
                  }}
                  cursor="pointer"
                >
                  Earn&nbsp;
                  {stabilityPoolAPR.isZero()
                    ? "-----"
                    : `${formatBNtoHumanReadable(stabilityPoolAPR, 0, 1)}% `}
                  on USDF
                </Text>
              </Tooltip>
            </Text>
          </Hide>
          <Show below="sm">
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                leftIcon={<HamburgerIcon />}
                size="sm"
                justifySelf={"left"}
                justifyContent={"left"}
                alignSelf={"left"}
                width={"100%"}
                mt={0}
              >
                Menu
              </MenuButton>
              <MenuList
                backgroundColor={"bgLightGreyNotTransparent"}
                fontFamily={"IBM Plex Mono"}
                fontWeight={"bold"}
              >
                <MenuItem
                  href="/"
                  as={Link}
                  icon={<FaExchangeAlt />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={currentPage === "/" ? "textPrimary" : "textSecondary"}
                >
                  Borrow
                </MenuItem>
                <MenuItem
                  href="/my-troves"
                  as={Link}
                  icon={<IoCubeOutline />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/my-troves"
                      ? "textPrimary"
                      : "textSecondary"
                  }
                >
                  My Troves
                </MenuItem>
                <MenuItem
                  href="/earn"
                  as={Link}
                  icon={<GoGraph />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/earn" ? "textPrimary" : "textSecondary"
                  }
                >
                  Earn
                </MenuItem>
                <MenuItem
                  href="/staking"
                  as={Link}
                  icon={<BsDatabase />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/staking" ? "textPrimary" : "textSecondary"
                  }
                >
                  Staking
                </MenuItem>
                <MenuItem
                  href="/history"
                  as={Link}
                  icon={<FaHistory />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/history" ? "textPrimary" : "textSecondary"
                  }
                >
                  History
                </MenuItem>
                <MenuItem
                  href="https://stats.fluidprotocol.xyz"
                  as={Link}
                  icon={<VscGraph />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/stats" ? "textPrimary" : "textSecondary"
                  }
                >
                  Stats
                </MenuItem>
                <MenuItem
                  href="https://mira.ly/swap/"
                  as={Link}
                  icon={<ExternalLinkIcon />}
                  backgroundColor={"bgLightGreyNotTransparent"}
                  color={
                    currentPage === "/swap" ? "textPrimary" : "textSecondary"
                  }
                >
                  Swap
                </MenuItem>
                <MenuDivider />
                <HStack spacing={2} justify="center" width="100%" p={2}>
                  <IconButton
                    as="a"
                    href="https://twitter.com/Fluid_Protocol"
                    target="_blank"
                    aria-label="Twitter"
                    icon={<FaXTwitter />}
                    size="sm"
                    variant="ghost"
                  />
                  <IconButton
                    as="a"
                    href="https://github.com/Hydrogen-Labs/fluid-protocol"
                    target="_blank"
                    aria-label="GitHub"
                    icon={<FaGithub />}
                    size="sm"
                    variant="ghost"
                  />
                  <IconButton
                    as="a"
                    href="https://discord.gg/PEQJZcPX8u"
                    target="_blank"
                    aria-label="Discord"
                    icon={<FaDiscord />}
                    size="sm"
                    variant="ghost"
                  />
                  <IconButton
                    as="a"
                    href="https://docs.hydrogenlabs.xyz/fluid-protocol-community"
                    target="_blank"
                    aria-label="Gitbook"
                    icon={<FaBook />}
                    size="sm"
                    variant="ghost"
                  />
                </HStack>
              </MenuList>
            </Menu>
          </Show>
        </GridItem>

        <GridItem colSpan={1} justifySelf="flex-end">
          {!isConnected ? (
            <ButtonGroup display="flex" justifyContent="flex-end" width="100%">
              <Button
                onClick={() => {
                  onClickConnect();
                }}
                size={["sm", "xs", "md"]}
                position="relative"
              >
                Connect
                {hasCollateralSurplus(assets) && (
                  <WarningIcon
                    color="red.500"
                    position="absolute"
                    top="-2px"
                    right="-2px"
                    boxSize="14px"
                  />
                )}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              flexDir={["column", "column", "row"]}
              justifySelf={"flex-end"}
              fontFamily={"IBM Plex Mono"}
              fontWeight={"bold"}
              w={["100%", "100%", "auto"]}
              spacing={[0, 0, 4]}
            >
              <Hide below="md">
                {isTestnet !== "testnet" && (
                  <Button
                    size={["sm", "xs", "md"]}
                    onClick={() =>
                      window.open(
                        "https://app.fuel.network/earn-points/",
                        "_blank"
                      )
                    }
                    leftIcon={
                      <Image src={FuelLogo} alt="Fuel Logo" boxSize="20px" />
                    }
                  >
                    Points
                  </Button>
                )}
                {isTestnet === "testnet" && <MintTestAssets />}
              </Hide>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  colorScheme={hasCollateralSurplus(assets) ? "red" : undefined}
                  position="relative"
                >
                  {account?.slice(0, 8) + "..." + account?.slice(-3)}
                  {hasCollateralSurplus(assets) && (
                    <WarningIcon
                      color="red.500"
                      position="absolute"
                      top="-2px"
                      right="-2px"
                      boxSize="14px"
                    />
                  )}
                </MenuButton>
                <MenuList
                  className="wallet-dropdown"
                  backgroundColor={"bgLightGreyNotTransparent"}
                  fontFamily={"IBM Plex Mono"}
                  fontWeight={"bold"}
                >
                  <MenuItem
                    backgroundColor={"bgLightGreyNotTransparent"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={copyAddress}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                    zIndex={1000}
                  >
                    <CopyIcon mr={2} /> Copy Address
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGreyNotTransparent"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={openExplorer}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <ExternalLinkIcon mr={2} /> View on Explorer
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGreyNotTransparent"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => {
                      onClickConnect();
                    }}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <UnlockIcon mr={2} /> Disconnect
                  </MenuItem>
                  <MenuItem
                    backgroundColor={"bgLightGreyNotTransparent"}
                    _hover={{
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                    onClick={() => {
                      switchNetwork();
                    }}
                    fontSize={["sm", "md"]}
                    fontWeight={"bold"}
                  >
                    <RepeatIcon mr={2} /> Switch to{" "}
                    {isTestnet === "testnet" ? "Mainnet" : "Testnet"}
                  </MenuItem>
                  {!assetsAdded && (
                    <MenuItem
                      backgroundColor={"bgLightGreyNotTransparent"}
                      _hover={{
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                      onClick={checkAndAddAssets}
                      fontSize={["sm", "md"]}
                      fontWeight={"bold"}
                    >
                      <DownloadIcon mr={2} /> Add Assets to Wallet
                    </MenuItem>
                  )}
                  <Divider />
                  <AssetMenuItem
                    symbol="FPT"
                    balance={fptBalance}
                    imageUrl="https://i.imgur.com/SLGOAB7.png"
                    price={fptPrice}
                    decimals={2}
                  />
                  <AssetMenuItem
                    symbol="USDF"
                    balance={usdfBalance}
                    imageUrl="https://i.imgur.com/qI2cyjF.jpg"
                    price={new BN(PRECISION)}
                    decimals={2}
                  />
                  {assets
                    .filter((asset) => !asset.balance.isZero())
                    .sort((a, b) => {
                      const valueA = a.balance.mul(a.price);
                      const valueB = b.balance.mul(b.price);
                      return valueB.gt(valueA) ? 1 : -1;
                    })
                    .map((asset) => (
                      <AssetMenuItem
                        key={asset.contractIds.assetId}
                        symbol={asset.symbol}
                        balance={asset.balance}
                        imageUrl={asset.contractIds.imageUrl}
                        price={asset.price}
                        decimals={4}
                      />
                    ))}
                  {hasCollateralSurplus(assets) && (
                    <>
                      <MenuItem
                        backgroundColor={"red.500"}
                        fontSize={["sm", "md"]}
                        cursor={"default"}
                        fontWeight={"bold"}
                      >
                        <WarningIcon mr={2} /> Claim Collateral Surplus from
                        liquidations or redemptions
                      </MenuItem>
                      {assets.map((asset) =>
                        asset.collateralSurplus.isZero() ? null : (
                          <MenuItem
                            key={`surplus-${asset.contractIds.assetId}`}
                            backgroundColor={"red.100"}
                            color={"red.900"}
                            _hover={{
                              backgroundColor: "red.200",
                            }}
                            fontSize={"sm"}
                            fontWeight={"bold"}
                            onClick={() => claimCollateralSurplus(asset)}
                            // center text
                            textAlign={"center"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            {formatBNtoHumanReadable(
                              asset.collateralSurplus,
                              9,
                              9
                            )}{" "}
                            {asset.symbol}
                          </MenuItem>
                        )
                      )}
                    </>
                  )}
                </MenuList>
              </Menu>
            </ButtonGroup>
          )}
        </GridItem>
      </Grid>
      <Divider />
    </>
  );
};
